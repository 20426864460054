import { AppReducerType } from "./ReduxTypes";

const CHANGE_CURRENT_VIEW = "CHANGE_CURRENT_VIEW";
const CHANGE_ONLINE_STATUS = "CHANGE_ONLINE_STATUS";
const CHANGE_APP_URI = "CHANGE_APP_URI";
const CHANGE_LOGGED_IN = "CHANGE_LOGGED_IN";
const CHANGE_ACTIVE_ROLES = "CHANGE_ACTIVE_ROLES";
const CHANGE_TOASTS = "CHANGE_TOASTS";
const CHANGE_UI_SETTINGS = "CHANGE_UI_SETTINGS";
const CHANGE_LOGGEDINUSER = "CHANGE_LOGGEDINUSER";
const CHANGE_UIACCOUNTSADMIN = "CHANGE_UIACCOUNTSADMIN";
const CHANGE_UILARGEMAP = "CHANGE_UILARGEMAP";


const appReducerStore: AppReducerType = {
  currentview: "",
  online: true,
  uri: true,
  loggedin: false,
  roles: [],
  toasts: {},
  uisettings: {},
  loggedInUser: null,
  uiaccountsadmin: null,
  uiLargeMap: {
    mapViewstate: "gardens"
  }
};

const appReducer = (state = { ...appReducerStore }, action) => {
  switch (action.type) {
    case CHANGE_CURRENT_VIEW: { state = { ...state, currentview: action.payload }; break; }
    case CHANGE_ONLINE_STATUS: { state = { ...state, online: action.payload }; break; }
    case CHANGE_APP_URI: { state = { ...state, uri: action.payload }; break; }
    case CHANGE_LOGGED_IN: { state = { ...state, loggedin: action.payload }; break; }
    case CHANGE_ACTIVE_ROLES: { state = { ...state, roles: action.payload }; break; }
    case CHANGE_TOASTS: { state = { ...state, toasts: action.payload }; break; }
    case CHANGE_UI_SETTINGS: { state = { ...state, uisettings: action.payload }; break; }
    case CHANGE_LOGGEDINUSER: { state = { ...state, loggedInUser: action.payload }; break; }
    // UI
    case CHANGE_UIACCOUNTSADMIN: { state = { ...state, uiaccountsadmin: action.payload }; break; }
    case CHANGE_UILARGEMAP: { state = { ...state, uiLargeMap: action.payload }; break; }

    default: { return state; }
  }
  return state;
};


export {
  appReducer,
  CHANGE_CURRENT_VIEW, CHANGE_ONLINE_STATUS, CHANGE_APP_URI,
  CHANGE_LOGGED_IN, CHANGE_ACTIVE_ROLES, CHANGE_TOASTS, CHANGE_UI_SETTINGS,
  CHANGE_LOGGEDINUSER,

  CHANGE_UIACCOUNTSADMIN, CHANGE_UILARGEMAP
}