import React, { Component } from 'react';
import { Route, Redirect } from 'react-router';

import { Layout } from './components/Layout';

import store from './rdx/store';

import { Routes } from "./Handlers/ViewHandler";

import { Login } from './components/Login/Login';
import { Home } from './components/Home';

import { Plants } from './components/Plants/Plants';
import { AddPlant } from './components/Plants/AddPlant';
import { EditPlant } from './components/Plants/EditPlant';
import { PlantImages } from './components/Plants/PlantImages';
import { AllPlantImages } from './components/Images/AllPlantImages';
import { CreatePlant } from './components/Plants/CreatePlant';
import { AddNewPlant } from './components/Plants/AddNewPlant';

import { PlantTypes } from './components/Plants/PlantTypes';
import { PlantFields } from './components/Plants/PlantFields';
import { FieldsPerType } from "./components/Plants/FieldsPerType";
import { PlantTags } from "./components/Plants/PlantTags";
import { Colors } from './components/Plants/Colors';
import { Snippets } from './components/Plants/Snippets';
import { Lists } from './components/Plants/Lists';
import { Tools } from './components/Plants/Tools';



import { Images } from './components/Images/Images';
import { UserList } from './components/UserList';
import { User } from './components/User';
import { ListItems } from './components/ListItems';
import { Inspiration } from './components/Inspiration/Inspiration';

import './custom.css'
import { Portal } from './components/Shared/Portal';
import { MessageBoxContainer } from './Handlers/MessageBoxes';
import { Operations } from './rdx/Operations';
import { UserPlants } from './components/Plants/UserPlants';
import { UserPlantImages } from './components/Plants/UserPlantImages';

import { Faqs } from './components/SystemObjects/Faqs';
import { Feedback } from './components/SystemObjects/Feedback';
import { AccountsAdmin } from './components/AccountsAdmin';
import { GardenActivities } from './components/Activities/GardenActivities';
import { OnboardingLists } from './components/Onboarding/OnboardingLists';
import { SystemSettings } from './components/SystemObjects/SystemSettings';
import { Discover } from './components/Discover/Discover';
import { UserStatus } from './components/reports/UserStatus';
import { Feed } from './components/Feed/Feed';
import { Comments } from './components/comments/Comments';
import { UserLists } from './components/UserLists';
import { Blog } from './components/Blog/Blog';
import { BlogAuthors } from './components/Blog/BlogAuthors';
import { DashBoard } from './components/dash/DashBoard';
import { ToolsScreen } from './components/Tools/ToolsScreen';
import { CreateQrCode } from './components/Tools/CreateQrCode';

import { Forum } from './components/Forum/Forum';
import { TestScreen } from './components/Test/TestScreen';
import { Statistics } from './components/Statistics/Statistics';
import { UserStatistics } from './components/Statistics/UserStatistics';
import { Purchases } from './components/Purchases/Purchases';
import { Subscriptions } from './components/Purchases/Subscriptions';
import { AdAdmin } from './components/AdManagement/AdAdmin';
import { AdClients } from './components/AdManagement/AdClients';
import { PostsManagement } from './components/PostsManagement/PostsManagement';
import { PlantImageOperations } from './components/PlantImageOperations/PlantImageOperations';
import { UserPlantImageOperations } from './components/PlantImageOperations/UserPlantImageOperations';
import { SocialImageOperations } from './components/PlantImageOperations/SocialImageOperations';
import { Combines } from './components/Combines/Combines';
import { PointOfInterests } from './components/Map/PointOfInterests';
import { LargeMap } from './components/Map/LargeMap';
import { ElasticSearchPlants } from './components/Elastic/ElasticSearchPlants';
import { ElasticSearchUsers } from './components/Elastic/ElasticSearchUsers';
import { ElasticSearchBlog } from './components/Elastic/ElasticSearchBlog';
import { ElasticSearchPoi } from './components/Elastic/ElasticSearchPoi';
import { ElasticSearchAll } from './components/Elastic/ElasticSearchAll';
import { ElasticSearchPosts } from './components/Elastic/ElasticSearchPosts';
import { Webforms } from './components/Webforms/Webforms';
import { SignalRAdmin } from './components/SignalR/SignalRAdmin';
import { MonthlyActivitiesAdmin } from './components/Monthlyactivities/MonthlyActivitiesAdmin';
import { PlantNetList } from './components/PlantNet/PlantNetList';
import { SubscriptionsWithoutUser } from './components/Purchases/SubscriptionsWithoutUser';
import { PlantGroups } from './components/PlantGroups/PlantGroups';
import { CityStatistics } from './components/Statistics/CityStatistics';

export default class App extends Component {
  static displayName = App.name;
  constructor(props) {
    super(props);
    this.unsubscribe = null;
    this.state = { loggedin: false };

    this.unsubscribe = store.subscribe(() => {
      this.setState({ loggedin: store.getState().app.loggedin });
    });
  }

  componentDidMount() {
    store.dispatch(Operations.getAllPlantImages);
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  render() {

    if (this.state.loggedin === true) {
      return (
        <>
          <Layout>
            <Route exact path={Routes.start} component={Home} />
            <Route exact path={Routes.users} component={UserList} />
            <Route exact path={Routes.userViewDefinition} component={User} />

            {/* Plants */}
            <Route exact path={Routes.plants} component={Plants} />
            <Route exact path={Routes.addplant} component={AddPlant} />
            <Route exact path={Routes.addnewplant} component={AddNewPlant} />
            <Route exact path={Routes.editplant} component={EditPlant} />
            <Route exact path={Routes.plantimages} component={PlantImages} />
            <Route exact path={Routes.allplantimages} component={AllPlantImages} />
            <Route exact path={Routes.createplant} component={CreatePlant} />

            <Route exact path={Routes.planttypes} component={PlantTypes} />
            <Route exact path={Routes.plantfields} component={PlantFields} />
            <Route exact path={Routes.fieldspertype} component={FieldsPerType} />
            <Route exact path={Routes.planttags} component={PlantTags} />
            <Route exact path={Routes.snippets} component={Snippets} />
            <Route exact path={Routes.tools} component={Tools} />
            <Route exact path={Routes.lists} component={Lists} />
            <Route exact path={Routes.colors} component={Colors} />
            <Route exact path={Routes.gardenactivities} component={GardenActivities} />
            <Route exact path={Routes.onboardinglists} component={OnboardingLists} />
            <Route exact path={Routes.discover} component={Discover} />

            <Route exact path={Routes.listitems} component={ListItems} />
            <Route exact path={Routes.images} component={Images} />

            <Route exact path={Routes.userplants} component={UserPlants} />
            <Route exact path={Routes.userplantimages} component={UserPlantImages} />
            <Route exact path={Routes.userlists} component={UserLists} />

            <Route exact path={Routes.inspiration} component={Inspiration} />

            <Route exact path={Routes.faq} component={Faqs} />
            <Route exact path={Routes.forum} component={Forum} />
            <Route exact path={Routes.feedback} component={Feedback} />

            <Route exact path={Routes.systemsettings} component={SystemSettings} />
            <Route exact path={Routes.dashboard} component={DashBoard} />
            <Route exact path={Routes.toolsscreen} component={ToolsScreen} />

            <Route exact path={Routes.feed} component={Feed} />
            <Route exact path={Routes.comments} component={Comments} />
            <Route exact path={Routes.blog} component={Blog} />
            <Route exact path={Routes.blogauthors} component={BlogAuthors} />
            <Route exact path={Routes.webforms} component={Webforms} />

            <Route exact path={Routes.postsmanagement} component={PostsManagement} />
            <Route exact path={Routes.plantimageoperations} component={PlantImageOperations} />
            <Route exact path={Routes.userplantimageoperations} component={UserPlantImageOperations} />
            <Route exact path={Routes.socialimageoperations} component={SocialImageOperations} />
            <Route exact path={Routes.combines} component={Combines} />
            <Route exact path={Routes.pointofinterests} component={PointOfInterests} />
            <Route exact path={Routes.largemap} component={LargeMap} />
            <Route exact path={Routes.elasticsearchplants} component={ElasticSearchPlants} />
            <Route exact path={Routes.elasticsearchusers} component={ElasticSearchUsers} />
            <Route exact path={Routes.elasticsearchblogs} component={ElasticSearchBlog} />
            <Route exact path={Routes.elasticsearchposts} component={ElasticSearchPosts} />

            <Route exact path={Routes.elasticsearchpois} component={ElasticSearchPoi} />
            <Route exact path={Routes.elasticsearchall} component={ElasticSearchAll} />
            <Route exact path={Routes.monthlyactivities} component={MonthlyActivitiesAdmin} />

            {/* Accounts */}
            <Route exact path={Routes.accountsadmin} component={AccountsAdmin} />
            <Route exact path={Routes.userstatus} component={UserStatus} />
            <Route exact path={Routes.purchases} component={Purchases} />
            <Route exact path={Routes.subscriptions} component={Subscriptions} />
            <Route exact path={Routes.subscriptionswithoutuser} component={SubscriptionsWithoutUser} />


            <Route exact path={Routes.testscreen} component={TestScreen} />
            <Route exact path={Routes.signalradmin} component={SignalRAdmin} />
            <Route exact path={Routes.statistics} component={Statistics} />
            <Route exact path={Routes.userstatistics} component={UserStatistics} />
            <Route exact path={Routes.citystatistics} component={CityStatistics} />

            <Route exact path={Routes.createqrcode} component={CreateQrCode} />

            <Route exact path={Routes.adadmin} component={AdAdmin} />
            <Route exact path={Routes.adclients} component={AdClients} />
            <Route exact path={Routes.plantnetlist} component={PlantNetList} />
            <Route exact path={Routes.plantgroups} component={PlantGroups} />


            <Redirect to="/" />
          </Layout>
          <Portal>
            <MessageBoxContainer />
          </Portal>
        </>
      );
    }

    return (<Login />);

  }
}
